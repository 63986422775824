export default [
    {
        title: 'Товары',
        route: 'products',
        icon: 'ShoppingBagIcon',
    },
    {
        title: 'Отгрузки',
        route: 'shipments',
        icon: 'TruckIcon',
    },
    {
        title: 'Поставки FBO',
        route: 'delivery',
        icon: 'PackageIcon',
    },
    {
        title: 'Калькулятор FBO',
        route: 'calculator',
        icon: 'PlayIcon',
    },
    {
        title: 'Настройки',
        route: 'setting',
        icon: 'SettingsIcon',
    },
    {
        title: 'Wiki',
        route: 'wiki',
        icon: 'BookOpenIcon',
        /*        children: [
                    // 2nd level
                    {
                        title: 'Invoice',
                        icon: 'i-mdi-file-document-outline',
                        children: [
                            // 3rd level
                            {
                                title: 'List',
                                to: 'apps-invoice-list',
                            },
                        ],
                    },
                ],*/
    },
    {
        title: 'Закупки',
        route: 'procurement',
        icon: 'ShoppingCartIcon',
    },
    {
        title: 'Отчеты',
        icon: 'ClipboardIcon',
        children: [
            {
                route: 'unit-report',
                title: 'unit-аналитика',
                icon: 'CircleIcon',
            },
            {
                route: 'charts',
                title: 'графики',
                icon: 'CircleIcon',
            },
        ],
    },
]